@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

ul, li { list-style: none; }
html {
  scroll-behavior: smooth;
}
html, body, #root {
  margin: 0;
  overflow-x: hidden;
  background-color: #2388FF;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.can-click-jump-text {
  cursor: pointer;
}

@font-face {
  font-family: 'Berlin Sans FB';
  src: url('./assets/fonts/BRLNSR.TTF') format('truetype');
}

@font-face {
  font-family: 'Berlin Sans FB Bold';
  src: url('./assets/fonts/BRLNSB.TTF') format('truetype');
}

@font-face {
  font-family: 'Berlin Sans FB Demi Bold';
  src: url('./assets/fonts/BRLNSDB.TTF') format('truetype');
}
